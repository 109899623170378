@import "/src/styles/mixin";

.footer {
  padding-top: 64px;
  padding-bottom: 80px;
  background-color: var(--secondary-color);
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("/images/pictures/footer-background.webp");

  @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
    padding-top: 32px;
    padding-bottom: 88px;
    background-size: unset;
  }

  &Wrap {
    display: flex;

    @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
      flex-direction: column-reverse;
      gap: 32px;
    }
  }

  &Right {
    flex: 0 1 423px;

    @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
      flex: unset;
      width: 100%;
    }
  }

  &Title {
    @include font14Bold;
    margin-bottom: 12px;
    color: var(--white-color);
  }

  &Pay {
    &List {
      display: flex;
      align-items: center;
      gap: 16px;
      margin-bottom: 32px;
    }
  }
}

.icon {
  width: 20px;
  height: 20px;
}
