@import "/src/styles/mixin";

.item {
  &Button {
    @include font14Bold;
    margin-bottom: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    pointer-events: none;
    color: var(--white-color);
    &Opened {
      width: 100%;
      .chevron {
        transform: rotate(180deg);
      }
    }
    .chevron {
      display: none;
      transition: transform 0.2s ease;
      @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
        display: block;
      }
    }
    @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
      margin-bottom: 0;
      padding: 14px 0;
      pointer-events: all;
    }
  }
  &Content {
    transition: height 0.2s ease;
  }
  @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
    border-bottom: 1px solid var(--white-color);
  }
}
