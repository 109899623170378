@import "/src/styles/mixin";

.accordion {
  display: flex;
  flex: 1 1 auto;
  margin-right: 32px;
  gap: 11%;
  @media only screen and (min-width: 1024px) and (max-width: 1365.98px) {
    gap: 2%;
  }
  @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
    flex-direction: column;
    margin-right: 0;
  }
  &List {
    display: flex;
    flex-direction: column;
    gap: 16px;
    @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
      padding: 14px 0;
    }
  }
  &Item {
    width: 180px;
    &.work {
      @include font14;
      color: var(--grey-lines-color);
    }
  }
  &Link {
    @include font14;
    color: var(--grey-lines-color);
    transition: color 0.2s ease;
    &:hover {
      color: var(--primary-color);
    }
  }
  &Social {
    &List {
      display: grid;
      grid-template-columns: repeat(3, auto);
      justify-content: flex-start;
      gap: 16px;
      margin: 32px 0;
      @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
        margin-bottom: 32px;
        display: flex;
      }
    }
    &Link {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      border-radius: var(--border-radius-small);
      border: 1px solid var(--grey-color);
      transition: border-color 0.2s ease;
      &:hover {
        border-color: var(--white-color);
      }
    }
  }
}
